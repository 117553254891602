import React from 'react'
import { graphql } from 'gatsby'
// import { Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Layout from '../components/layout'

import { styles } from '../styles/styles.jsx'

const Success = ({ data }) => (
  <Layout>
    <BackgroundImage
      className={styles.contactPageBackgroundImage}
      fluid={data.datoCmsContact.contactBackgroundImage.fluid}
    >
      <div className={styles.container} style={{height: '70vh'}}>
        <div className={styles.contactContainer}>
          <h1 className={styles.contactTitle}>
            Success
          </h1>
          <p className={styles.contactText}>
          Thanks for contacting Monx Design House, we will get back to you shortly.
          </p>
          <div>

          </div>
        </div>
      </div>
    </BackgroundImage>
  </Layout>
)

export default Success

export const SuccessQuery = graphql`
  query successPageQuery {
    datoCmsContact {
      contactBackgroundImage {
        fluid(maxWidth: 2000, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      contactTitle
      contactText
    }
  }
`
